export default {
    en: {
        translation: {
            Location: 'Location',
            Destination: 'Destination',
            'Check in - Check out': 'Check in - Check out',
            'From - To': 'From - To',
            Adults: 'Adults',
            Adult: 'Adult',
            Children: 'Children',
            Child: 'Child',
            Infants: 'Infants',
            Infant: 'Infant',
            Search: 'Search',
            Guests: 'Guests',
            Travelers: 'Travelers',
        },
    },
    it: {
        translation: {
            Location: 'Luogo',
            Destination: 'Destinazione',
            'Check in - Check out': 'Check in - Check out',
            'From - To': 'Da - A',
            Adults: 'Adulti',
            Adult: 'Adulto',
            Children: 'Bambini',
            Child: 'Bambino',
            Infants: 'Infanti',
            Infant: 'Infante',
            Search: 'Cerca',
            Guests: 'Ospiti',
            Travelers: 'Viaggiatori',
        },
    },
};
