import { Buffer } from 'buffer';
import BaseSearch from './searchWithoutTabs';
import SearchWithTabs from './searchWithTabs';

const SearchComponent = (props) => {
    const id = props.data;

    const buffer = Buffer.from(id, 'base64');
    const text = buffer.toString('ascii');
    const array = text.split('_');

    // defines search component type
    const typeArray = array[0].split('=');
    const searchTypeValue = typeArray[1];

    // defines services
    const servicesArray = array[1].split('=');
    const servicesValue = servicesArray[1].split(',');

    return (
        <div className="hola-agency-search">
            <div className="hola-agency-search__tabs tabs js-tabs">
                {
                    searchTypeValue === '0'
                        ? (
                            <SearchWithTabs
                                services={servicesValue}
                                searchComponentType={searchTypeValue}
                            />
                        )
                        : (
                            <BaseSearch
                                services={servicesValue}
                                searchComponentType={searchTypeValue}
                            />
                        )
                }
            </div>
        </div>
    );
};

export default SearchComponent;
