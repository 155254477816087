import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';
import moment from 'moment';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import {
    ArrowSmallDownIcon,
    CalendarIcon,
    UserIcon,
    LocationIcon,
    AdultIcon,
    ChildIcon,
    InfantIcon,
    MinusIcon,
    PlusIcon,
} from '../../assets/images/icons';

const BaseSearch = ({ activeKey, services, searchComponentType }) => {
    const { t } = useTranslation();

    function closeAllDropdowns() {
        const classes = document.querySelectorAll('.-is-dd-wrap-active');
        if (classes) {
            classes.forEach((el) => {
                el.classList.remove('-is-dd-wrap-active');
            });
        }

        const targets = document.querySelectorAll('.js-form-dd');
        if (!targets) return;

        targets.forEach((el) => {
            const eventElement = el.querySelector('[data-x-dd]');
            const eventTarget = el.querySelector('[data-x-dd-click]');
            const attributes = eventTarget.getAttribute('data-x-dd-click').split(', ');

            attributes.forEach((elm) => {
                eventElement?.classList.remove('-is-active');
                const target = document.querySelector(`[data-x-dd=${elm}]`);
                const toggleClass = target?.getAttribute('data-x-dd-toggle');
                target?.classList.remove(toggleClass);
            });
        });
    }

    const Events = () => {
        function init() {
            const targets = document.querySelectorAll('[data-x-click]');
            if (!targets) return;

            targets.forEach((eventTarget) => {
                const attributes = eventTarget.getAttribute('data-x-click').split(', ');

                attributes.forEach((el) => {
                    const target = document.querySelector(`[data-x=${el}]`);

                    eventTarget.addEventListener('click', () => {
                        const toggleClass = target.getAttribute('data-x-toggle');
                        target.classList.toggle(toggleClass);
                    });
                });
            });
        }

        function ddInit() {
            const targets = document.querySelectorAll('.js-form-dd');
            if (!targets) return;

            targets.forEach((el) => {
                const eventTarget = el.querySelector('[data-x-dd-click]');
                const attributes = eventTarget.getAttribute('data-x-dd-click').split(', ');

                attributes.forEach((el2) => {
                    const target = el.querySelector(`[data-x-dd=${el2}]`);
                    const toggleClass = target?.getAttribute('data-x-dd-toggle');

                    eventTarget.addEventListener('click', () => {
                        if (eventTarget.querySelector('.js-dd-focus')) {
                            eventTarget.querySelector('.js-dd-focus').focus();
                        }

                        if (target?.classList.contains(toggleClass)) {
                            target.classList.remove(toggleClass);
                            el.classList.remove('-is-dd-wrap-active');
                        } else {
                            closeAllDropdowns();
                            target?.classList.add(toggleClass);
                            el.classList.add('-is-dd-wrap-active');
                        }
                    });
                });
            });
        }

        return {
            init,
            ddInit,
            closeAllDropdowns,
        };
    };

    function countChange() {
        const targets = document.querySelectorAll('.js-form-counters');
        if (!targets) return;

        targets.forEach((item) => {
            const counters = item.querySelectorAll('.js-counter');

            counters.forEach((el) => {
                const count = el.querySelector('.js-count');
                const buttonDown = el.querySelector('.js-down');
                const buttonUp = el.querySelector('.js-up');

                buttonDown.addEventListener('click', () => {
                    if (count.innerHTML !== 0) {
                        count.innerHTML = parseInt(count.innerHTML, 10) - 1;

                        if (el.getAttribute('data-value-change')) {
                            item.querySelector(el.getAttribute('data-value-change')).innerHTML = parseInt(count.innerHTML, 10);
                        }
                    }
                });

                buttonUp.addEventListener('click', () => {
                    count.innerHTML = parseInt(count.innerHTML, 10) + 1;

                    if (el.getAttribute('data-value-change')) {
                        item.querySelector(el.getAttribute('data-value-change')).innerHTML = parseInt(count.innerHTML, 10);
                    }
                });
            });
        });
    }

    useEffect(() => {
        const initComponents = () => {
            Events().init();
            Events().ddInit();
            countChange();
        };

        document.fonts.ready.then(() => {
            initComponents();
        });
    }, []);

    const countryKEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_COUNTRY;
    const [selectedDates, setSelectedDates] = useState({ start: moment(), end: moment() });
    const date = [
        selectedDates.start.format('YYYY-MM-DD'),
        selectedDates.end.format('YYYY-MM-DD'),
    ];
    const [location, setLocation] = useState('');
    const [adultNumber, setAdultNumber] = useState(0);
    const [childNumber, setChildNumber] = useState(0);
    const [infantNumber, setInfantNumber] = useState(0);

    useEffect(() => {
        const dateInput = $('input[name="dates"]');

        dateInput.daterangepicker({
            autoApply: true,
            minDate: moment(),
        }, (start, end) => {
            setSelectedDates({ start, end });
        });

        return () => {
            dateInput.data('daterangepicker').remove();
        };
    }, []);

    const handleSearch = () => {
        const searchParams = new URLSearchParams();
        let path = '';
        // search with tabs
        if (searchComponentType === '1') {
            if (activeKey === '1') {
                path = 'accommodations';
            } else {
                path = 'tours';
            }
            // search without tabs
        } else {
            (() => {
                if (services[0] === '1') {
                    path = 'accommodations';
                } else {
                    path = 'tours';
                }
            })();
        }
        searchParams.append('widget-type', path);
        selectedDates.start !== '' && selectedDates.end !== '' && searchParams.append('widget-date', date);
        location !== '' && searchParams.append('widget-location', location);
        adultNumber !== undefined && searchParams.append('widget-adult', adultNumber);
        childNumber !== undefined && searchParams.append('widget-children', childNumber);
        infantNumber !== undefined && searchParams.append('widget-infant', infantNumber);

        const url = new URL(`https://${window.location.host}/widget-results`);
        url.search = searchParams.toString();
        window.location.href = url.toString();
    };

    return (
        <div className="hola-agency-tabs__content js-tabs-content">
            <div className="hola-agency-tab__item -tab-item-1 is-tab-el-active">
                <div className="mainSearch">
                    <div className="hola-agency-search__grid">
                        <div className="hola-agency-search-menu__loc searchMenu-loc js-form-dd">
                            <div data-x-dd-click="searchMenu-loc" className="hola-agency-search__item">
                                <div className="label">
                                    <LocationIcon />
                                    {activeKey === '1'
                                        ? <span>{t('Location')}</span>
                                        : <span>{t('Destination')}</span>}
                                </div>
                                {/* <div className="placeholder">
                                    <input
                                        autoComplete="off"
                                        type="search"
                                        placeholder="Enter a location"
                                        className="js-search js-dd-focus"
                                    />
                                    <ArrowSmallDownIcon />
                                </div> */}
                                <div className="placeholder">
                                    <ReactGoogleAutocomplete
                                        className="ant-input"
                                        apiKey={countryKEY}
                                        onPlaceSelected={(place) => {
                                            setLocation(place?.formatted_address);
                                        }}
                                    />
                                    <ArrowSmallDownIcon />
                                </div>
                            </div>
                            {/* <div
                                className="hola-agency-search__menu__dropdown
                                js-popup-window"
                                data-x-dd="searchMenu-loc"
                                data-x-dd-toggle="-is-active"
                            >
                                <div
                                    className="hola-agency-search__menu__dropdown__wrapper"
                                >
                                    <div className="js-results " />
                                </div>
                            </div> */}
                        </div>

                        <div className="hola-agency-search-menu__date searchMenu-date">
                            <div data-x-dd-click="searchMenu-date" className="hola-agency-search__item">
                                <div className="label">
                                    <CalendarIcon />
                                    {activeKey === '1'
                                        ? <span>{t('Check in - Check out')}</span>
                                        : <span>{t('From - To')}</span>}
                                </div>
                                <div className="placeholder">
                                    <input
                                        type="text"
                                        name="dates"
                                    />
                                    <ArrowSmallDownIcon />
                                </div>
                            </div>
                        </div>

                        <div className="hola-agency-search-menu__guests searchMenu-guests js-form-dd js-form-counters">
                            <div
                                data-x-dd-click="searchMenu-guests"
                                className="hola-agency-search__item"
                            >
                                <div className="label">
                                    <UserIcon />
                                    {activeKey === '1'
                                        ? <span>{t('Guests')}</span>
                                        : <span>{t('Travelers')}</span>}
                                </div>

                                <div className="placeholder">
                                    <span className="js-count-adult">0</span>
                                    {' '}
                                    {t('Adults')}
                                    {' '}
                                    -
                                    {' '}
                                    <span className="js-count-child">0</span>
                                    {' '}
                                    {t('Children')}
                                    {' '}
                                    -
                                    {' '}
                                    <span className="js-count-infants">0</span>
                                    {' '}
                                    {t('Infants')}
                                    <ArrowSmallDownIcon />
                                </div>
                            </div>

                            <div
                                className="hola-agency-search__menu__dropdown"
                                data-x-dd="searchMenu-guests"
                                data-x-dd-toggle="-is-active"
                            >
                                <div className=" hola-agency-search__menu__dropdown__wrapper">
                                    <div className=" hola-agency-search__menu__dropdown__guests-row">
                                        <div>
                                            <div className="hola-agency-search__menu__dropdown__label">
                                                <AdultIcon />
                                                <span>{t('Adults')}</span>
                                            </div>
                                        </div>

                                        <div className="hola-agency-search__menu__dropdown__guests__buttons">
                                            <div className="js-counter" data-value-change=".js-count-adult">
                                                <button
                                                    className="button js-down"
                                                    type="button"
                                                    onClick={() => setAdultNumber(adultNumber - 1)}
                                                    disabled={adultNumber === 0}
                                                >
                                                    <MinusIcon />
                                                </button>

                                                <div className="">
                                                    <div
                                                        className="hola-agency-search__menu__dropdown__counter js-count"
                                                    >
                                                        0
                                                    </div>
                                                </div>

                                                <button
                                                    className="button js-up"
                                                    type="button"
                                                    onClick={() => setAdultNumber(adultNumber + 1)}
                                                >
                                                    <PlusIcon />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="hola-agency-search__menu__dropdown__guests-row">
                                        <div>
                                            <div className="hola-agency-search__menu__dropdown__label">
                                                <ChildIcon />
                                                <span>{t('Children')}</span>
                                            </div>
                                        </div>

                                        <div className="hola-agency-search__menu__dropdown__guests__buttons">
                                            <div className="js-counter" data-value-change=".js-count-child">
                                                <button
                                                    className="button js-down"
                                                    type="button"
                                                    onClick={() => setChildNumber(childNumber - 1)}
                                                    disabled={childNumber === 0}
                                                >
                                                    <MinusIcon />
                                                </button>

                                                <div className="">
                                                    <div
                                                        className="hola-agency-search__menu__dropdown__counter js-count"
                                                    >
                                                        0
                                                    </div>
                                                </div>

                                                <button
                                                    className="button js-up"
                                                    type="button"
                                                    onClick={() => setChildNumber(childNumber + 1)}
                                                >
                                                    <PlusIcon />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="hola-agency-search__menu__dropdown__guests-row">
                                        <div>
                                            <div className="hola-agency-search__menu__dropdown__label">
                                                <InfantIcon />
                                                <span>{t('Infants')}</span>
                                            </div>
                                        </div>

                                        <div className="hola-agency-search__menu__dropdown__guests__buttons">
                                            <div className="js-counter" data-value-change=".js-count-infants">
                                                <button
                                                    className="button js-down"
                                                    type="button"
                                                    onClick={() => {
                                                        setInfantNumber(infantNumber - 1);
                                                    }}
                                                    disabled={infantNumber === 0}
                                                >
                                                    <MinusIcon />
                                                </button>

                                                <div>
                                                    <div
                                                        className="hola-agency-search__menu__dropdown__counter js-count"
                                                    >
                                                        0
                                                    </div>
                                                </div>

                                                <button
                                                    className="button js-up"
                                                    type="button"
                                                    onClick={() => {
                                                        setInfantNumber(infantNumber + 1);
                                                    }}
                                                >
                                                    <PlusIcon />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="hola-agency-button__item">
                            <button
                                className="hola-agency-search__button button"
                                type="button"
                                onClick={() => handleSearch()}
                            >
                                {t('Search')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BaseSearch;
