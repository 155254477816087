import { useState, useEffect } from 'react';
import { AccommodationIcon, TourIcon } from '../../assets/images/icons';
import BaseSearch from '../searchWithoutTabs';

const allServices = [
    {
        key: '1',
        name: 'Accommodations',
        icon: <AccommodationIcon />,
    },
    {
        key: '2',
        name: 'Tours',
        icon: <TourIcon />,
    },
];

const SearchWithTabs = ({ services, searchComponentType }) => {
    const [activeKey, setActiveKey] = useState(services[0]);
    const availableServices = [];

    allServices.forEach((service) => {
        const isAvailable = services.includes(service.key);
        if (isAvailable) {
            availableServices.push(service);
        }
    });

    const Tabs = () => {
        function singleTab(target) {
            const controls = target.querySelector('.js-tabs-controls');
            const controlsItems = target.querySelectorAll('.js-tabs-controls .js-tabs-button');
            // const content = target.querySelector('.js-tabs-content');

            for (let l = 0; l < controlsItems.length; l++) {
                const el = controlsItems[l];

                el.addEventListener('click', () => {
                    // const selector = el.getAttribute('data-tab-target');

                    controls.querySelector('.is-tab-el-active').classList.remove('is-tab-el-active');
                    // content.querySelector('.is-tab-el-active')
                    // .classList.remove('is-tab-el-active');

                    // console.log(controls.querySelector('.is-active'))
                    // console.log(content.querySelector('.is-active'))

                    el.classList.add('is-tab-el-active');
                    // content.querySelector(selector).classList.add('is-tab-el-active');
                });
            }
        }

        function init() {
            const targets = document.querySelectorAll('.js-tabs');
            if (!targets) return;

            targets.forEach((el) => {
                singleTab(el);
            });
        }

        return {
            init,
        };
    };

    useEffect(() => {
        const initComponents = () => {
            Tabs();
        };

        document.fonts.ready.then(() => {
            initComponents();
        });
    }, []);

    return (
        <div>
            {/* dynamic based on props */}
            <div className="hola-agency-search__tabs__controls js-tabs-controls">
                {availableServices.map((service) => (
                    <div key={service.key}>
                        <button
                            className={`js-tabs-button ${activeKey === service.key && 'is-tab-el-active'}`}
                            data-tab-target={`.-tab-item-${service.key}`}
                            type="button"
                            onClick={() => {
                                setActiveKey(service.key);
                            }}
                        >
                            {service.icon}
                            <span>{service.name}</span>
                        </button>
                    </div>
                ))}
            </div>
            <BaseSearch
                activeKey={activeKey}
                services={services}
                searchComponentType={searchComponentType}
            />
        </div>
    );
};
export default SearchWithTabs;
